// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { NpsEnvironmentConfig } from "./nps-environment-config";

export const npsEnvironment: NpsEnvironmentConfig = {
  production: false,
  api: {
    'CARIAD': {
      url: 'https://azfun-azuf-euw-p-nps-cariad.azurewebsites.net',
      clientId: 'fb6e3f16-ae27-4111-b06e-014a92b2fa4d',
      authority: 'https://login.microsoftonline.com/c5f6f6e0-4c59-4aa1-bcd7-033f5f211b1c',
      scopes: ['api://fb6e3f16-ae27-4111-b06e-014a92b2fa4d/API']
    },
    'VWGPROD': {
      url: 'https://azfun-azuf-euw-p-nps-vwgprod.azurewebsites.net',
      clientId: '2876e161-004c-41b9-a0b3-8c0086a1bc4f',
      authority: 'https://login.microsoftonline.com/9664fb57-6ab5-4cf3-8c69-2392f2ac06a3',
      scopes: ['api://2876e161-004c-41b9-a0b3-8c0086a1bc4f/API']
    },
    'VWGST': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    },
    'PowerCo': {
      url: 'https://azfun-azuf-euw-p-nps-powerco.azurewebsites.net',
      clientId: '9a07bf52-fa4b-471e-9e3c-e826d443b1c8',
      authority: 'https://login.microsoftonline.com/a8cf543a-438a-4e26-96a1-a99cfa3c6b07',
      scopes: ['api://9a07bf52-fa4b-471e-9e3c-e826d443b1c8/API']
    },
    'VWGITC': {
      url: '',
      clientId: '',
      authority: '',
      scopes: ['']
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
