
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { ProjectProfileDto } from './project-profile.model';
import { NpsStore } from '../../../services/nps.store';
import { NpsConfigurationApiUrl } from '../../shared/api/configuration.service';

export const NpsProjectApiUrl = {
  ProjectGetList: () => "/api/projects/profiles",
  ProjectGetById: (id:string) => `/api/projects/profiles/${id}`,
  ProjectCreate:() => "/api/projects/profiles",
  ProjectUpdate:(id: string) => `/api/projects/profiles/${id}`,
  ProjectDeleteById: (id:string) => `/api/projects/profiles/${id}`
}

@Injectable({providedIn: 'root'})
export class ProjectProfileService extends BaseApiService{

  constructor(store: NpsStore) {
    super(store.apiUrl);
  }

  getValidators(method: keyof typeof NpsProjectApiUrl) {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { method });
  }

  //#region Project Profiles

  projectProfiles(): Observable<ProjectProfileDto[]> {
    return super.get<ProjectProfileDto[]>(NpsProjectApiUrl.ProjectGetList());
  }

  projectProfileGet(id: string): Observable<ProjectProfileDto> {
    return super.get(NpsProjectApiUrl.ProjectGetById(id));
  }

  projectProfileCreate(post: ProjectProfileDto): Observable<ProjectProfileDto> {
    return super.post(NpsProjectApiUrl.ProjectCreate(), post)
  }

  projectProfileUpdate(id: string, post: ProjectProfileDto): Observable<ProjectProfileDto> {
    return super.put(NpsProjectApiUrl.ProjectUpdate(id), post);
  }

  projectProfileDelete(id: string): Observable<ProjectProfileDto> {
    return super.delete(NpsProjectApiUrl.ProjectDeleteById(id))
  }

  //#endregion  
}
