import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ValidatedFormControl } from "./validators/validated-form-control";
import { npsRegexValidator } from "./validators/nps-regex-validator";
import { ValidationRule } from "./models/validation-rule";
import { npsNotEmptyValidator } from "./validators/nps-notempty-validator";
import { npsMinIntValidator } from './validators/nps-minint-validator';
import { npsMaxIntValidator } from "./validators/nps-maxint-validator";
import { notEqualValidator } from "./validators/nps-string-validator";
import { npsMinLengthValidator } from './validators/nps-minlength-validator';
import { npsMaxLengthValidator } from "./validators/nps-maxlength-validator";

export function getObjectKeys(obj: { [key: string]: string }): string[] {
  return Object.keys(obj);
}

export function findKeyByValue<T extends object, V extends T[keyof T]>(obj: T, value: V): keyof T | undefined {
  return (Object.keys(obj) as Array<keyof T>).find(key => obj[key] === value);
}

export function nameof<T>(name: keyof T): string {
  return name as string;
}

export function isNullOrEmpty(value: string | null | undefined): boolean {
  return value === null || value === undefined || value.trim() === '';
}

//export function getFieldName<T extends object, K extends keyof T>(o: T, key: K): K {
//  return key;
//}

export function enumValues<T>(item: T, removeNumbers: boolean = false): Array<T[keyof T]> {
  return (
    Object.values(item as object)
      .filter((e) => !removeNumbers || typeof e == 'number')
      .map((e) => item[e as keyof T]) as Array<T[keyof T]>
  );
}

export function enumToLabelValueArray<T>(enumObj: T): { label: string, value: string }[] {
  return Object.keys(enumObj as object)
    .filter(key => isNaN(Number(key)))
    .map(key => ({ label: key, value: enumObj[key as keyof T] as unknown as string }));
}

export function mapToLabelValueArray<K, V>(map: Map<K, V>): { value: K; label: V }[] {
  return Array.from(map, ([value, label]) => ({ value, label }));
}

export function formAddValidators(fg: FormGroup, validators: ValidationRule[]): FormGroup {
  //Object.keys(validators)
  validators
  .forEach(vg => {
      formItemAddValidators(fg, vg.property,  vg.validators as Array<any>)
    });
  return fg;
}

export function formValidateFormGroup(fg: FormGroup): void {
  Object.values(fg.controls).forEach(control => {
    control.markAsTouched();
    control.updateValueAndValidity();
  });
}

//export function toFormGroup(fb: FormBuilder, model: any): FormGroup {
//  const formGroup = fb.group({});
//  Object.keys(model).forEach(key => {
//    if (Array.isArray(model[key])) {
//      formGroup.addControl(key, toFormArray(fb, model[key]));
//    } else if (typeof model[key] === 'object' && model[key] !== null) {
//      formGroup.addControl(key, toFormGroup(fb, model[key]));
//    } else {
//      formGroup.addControl(key, new FormControl(model[key]));
//    }
//  });
//  return formGroup;
//}

//export function toFormArray(fb: FormBuilder, array: any[]): FormArray {
//  const formArray: FormArray<any> = fb.array([]);
//  array.forEach(item => {
//    if (Array.isArray(item)) {
//      formArray.push(toFormArray(fb, item));
//    } else if (typeof item === 'object' && item !== null) {
//      formArray.push(toFormGroup(fb, item));
//    } else {
//      formArray.push(new FormControl(item));
//    }
//  });
//  return formArray;
//}

export function fillFormGroupValuesWithObject(formGroup: FormGroup, data: { [key: string]: any }): void {
  Object.keys(data).forEach(key => {
    if (formGroup.controls[key]) {
      formGroup.controls[key].setValue(data[key]);
    }
  });
}

function formItemAddValidators(fg: FormGroup, property: string, serverValidators: Array<any>) {
  let validator: ValidatorFn[] = [];
  //prepare validators
  serverValidators.forEach(vo => {
    vo.fmessage = (vo.message ?? 'The field is invalid').replace("{PropertyName}", property);
    switch (vo.name.split(':')[0]) {
      case 'NotEmptyValidator':
        vo.validator = 'required';
        //validator.push(Validators.required);
        validator.push(npsNotEmptyValidator(vo.fmessage))
        break;
      case 'MinimumLengthValidator':
        vo.validator = 'minlength';
        validator.push(npsMinLengthValidator(vo.min, `The value should be at least ${vo.min} characters long`));
        break;//min max
      case 'MaximumLengthValidator':
        vo.validator = 'maxlength';
        validator.push(npsMaxLengthValidator(vo.max, `The value should not contain more than ${vo.max} characters`));
        break;//min max
      case 'LessThanOrEqualValidator':
        vo.validator = 'max';
        validator.push(npsRegexValidator('[-0-9]*', `Only digits are allowed`));
        validator.push(npsMaxIntValidator(vo.valueToCompare, `The value must be lesser or equal to ${vo.valueToCompare}`));
        break;
      case 'GreaterThanOrEqualValidator':
        vo.validator = 'min';
        validator.push(npsRegexValidator('[-0-9]*', `Only digits are allowed`));
        validator.push(npsMinIntValidator(vo.valueToCompare, `The value must be greater or equal to ${vo.valueToCompare}`));
        break;
      case 'EmailValidator':
        vo.validator = 'email';
        validator.push(Validators.email);
        break;//min max
      case 'RegularExpressionValidator':
        vo.validator = 'pattern';
        validator.push(npsRegexValidator(vo.expression, vo.fmessage));
        break;//expression
      case 'GuidFormatValidator':
        vo.validator = 'pattern';
        validator.push(npsRegexValidator(`^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$`, vo.message));
        break;
      case 'NotEqualValidator':
        vo.validator = 'notequal';
        validator.push(notEqualValidator(vo.value, vo.message));
        break;
      default:
        console.log("Validator not found", vo.name);
        break;
    }
  });

  let names = property.split('.');

  //FormControl FormGroup FormArray

  if (names.length == 0) {
    return;
  }
  const name = names[0];
  if (!fg.controls[name]) {
    return;
  }
  if (names.length == 1) {
    fg.setControl(name, new ValidatedFormControl(fg.controls[name] as FormControl, validator, serverValidators));
  } else {
    if (fg.controls[name] instanceof FormArray) { //array e.g. translations
      let fga = fg.controls[name] as FormArray;
      fga.controls.forEach(fgac => {
        const subgr = fgac as FormGroup;
        subgr.setControl(names[1], new ValidatedFormControl(subgr.controls[names[1]] as FormControl, validator, serverValidators));
      })
    } else { //sub object
      if (fg.controls[name] instanceof FormGroup) {
        const subgr = fg.controls[name] as FormGroup;
        subgr.setControl(names[1], new ValidatedFormControl(subgr.controls[names[1]] as FormControl, validator, serverValidators));
      }
    }
  }
}

