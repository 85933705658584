import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { NotificationService } from './notification.service';

export class BaseApiService {

  baseUrl!: string;
  httpClient!: HttpClient;
  notification!: NotificationService;
  handleErrors: boolean = true;

  constructor(baseUrl: string) {

    this.baseUrl = baseUrl;
    this.httpClient = inject(HttpClient);
    this.notification = inject(NotificationService);

  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected httpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
  }

  protected get<TOut>(url: string, filter: any = null): Observable<TOut> {
    return this.httpClient.get<TOut>(`${this.baseUrl}${url}`, { ...this.httpOptions(), params: filter })
      .pipe(catchError((e) => this.errorHandler(e)))
  }

  protected post<TIn, TOut>(url: string, post: TIn): Observable<TOut> {
    return this.httpClient.post<TOut>(`${this.baseUrl}${url}`, JSON.stringify(post), this.httpOptions())
      .pipe(catchError((e) => this.errorHandler(e)))
  }

  protected postWithHeaders<TIn, TOut>(url: string, post: TIn): Observable<{ body: TOut, headers: HttpHeaders }> {
    return this.httpClient.post<TOut>(`${this.baseUrl}${url}`, JSON.stringify(post), { ...this.httpOptions(), observe: 'response' })
      .pipe(
        map(response => ({ body: response.body as TOut, headers: response.headers })),
        catchError((e) => this.errorHandler(e))
      );
  }

  protected postWithBlobResponse<TIn>(url: string, post: TIn): Observable<Blob> {
    return this.httpClient.post(`${this.baseUrl}${url}`, post, { headers: {'Content-Type': 'application/json', 'Accept':'text/csv' }, responseType: 'blob', observe: 'body'})
      .pipe(catchError((e) => this.errorHandler(e)))
  }

  protected put<TIn, TOut>(url: string, post: TIn): Observable<TOut> {
    return this.httpClient.put<TOut>(`${this.baseUrl}${url}`, JSON.stringify(post), this.httpOptions())
      .pipe(catchError((e) => this.errorHandler(e)))
  }

  protected delete<TOut>(url: string) {
    return this.httpClient.delete<TOut>(`${this.baseUrl}${url}`, this.httpOptions())
      .pipe(catchError((e) => this.errorHandler(e)))
  }

  protected errorHandler(response: any): Observable<never> {
    if (!this.handleErrors || !response || !response.error) {
      return throwError(response);
    }
    if (!response.error) {
      //general angular error
      this.notification.error(response.name, response.message);
    } else {
      const error = response.error;
      if (error.message == 'Validation Exception') {
        this.validationError(error.message ?? error.errorCode, error.data ?? error.errorCode);
      } else if (error.message) {
        this.notification.error(error.message ?? error.errorCode, error.data ? JSON.stringify(error.data, null, 15) : error.errorCode);
      } else {
        this.notification.error(response.name, response.message)
      };
    }
    return throwError(response);
  }

  protected validationError(message: string, data: { [key: string]: string[] }) {
    const formattedData = Object.entries(data).map(([key, value]) => `<li><strong>${key}:</strong> <ul>${value.map(v => `<li>${v}</li>`).join('')}</ul></li>`).join('');
    this.notification.error(message, `<ul>${formattedData}</ul>`);
  }
}
