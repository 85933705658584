import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface LayoutConfig {
  secondLogoText: string | null;
  secondLogoIcon: string;
  secondLogoUrl: string;
}

interface LayoutState {
  sidebarMenuIconVisible: boolean;
  sidebarMenuActive: boolean;
}

@Injectable({ providedIn: 'root' })
export class LayoutService {

  public progressBarRequests$ = new BehaviorSubject<number>(0);

  state: LayoutState = {
    sidebarMenuIconVisible: false,
    sidebarMenuActive: false
  };

  config: LayoutConfig = {
    secondLogoText: null,
    secondLogoIcon: '',
    secondLogoUrl: ''
  };

  constructor() {

  }

  startLoading() {
    this.progressBarRequests$.next(this.progressBarRequests$.value + 1);
  }

  stopLoading() {
    this.progressBarRequests$.next(this.progressBarRequests$.value - 1);
  }

  onMenuToggle() {
    this.state.sidebarMenuActive =
      !this.state.sidebarMenuActive;
  }

}
