import { AbstractControl, ValidationErrors } from "@angular/forms";

export function npsNotEmptyValidator(message: string): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    const val = control.value || '';

    const arr = val as string[];
    if (Array.isArray(arr)) {
      if (arr.length > 0)
        return null; // Valid if array has at least one item
      else
        return {
          npsNotEmptyValidator: message
        };
    }

    return !val.trim ? null : (val.trim().length ? null : { npsNotEmptyValidator: message });
  };
}
