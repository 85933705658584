import { FormControl, ValidatorFn } from "@angular/forms";

export class ValidatedFormControl extends FormControl {
  public serverValidators: any[] = [];

  constructor(control: FormControl, validators: ValidatorFn | ValidatorFn[] | null, serverValidators: any[] = []) {
    super(control.value);
    this.setValidators(validators);
    this.serverValidators = serverValidators;
  }
}
