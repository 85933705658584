
import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, tap } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { PermissionDictionary, TenantDto } from './configuration.model';
import { NpsStore } from '../../../services/nps.store';

export const NpsConfigurationApiUrl = {
  ConfigurationTenants: () => "/api/configuration/tenants",
  ConfigurationUserRoles: () => "/api/configuration/roles",
  ConfigurationPermissions: () => "/api/configuration/permissions",
  ConfigurationValidators: () => "/api/configuration/validators",
  ConfigurationVariableList: () => "/api/configuration/variables",

  ConfigurationConfiguredDeploymentStages: () => "/api/configuration/validation/deploymentStages-values",
  ConfigurationSupportedAzureLocationsForHubs: () => "/api/configuration/validation/azureLocations/hubs-values",
  ConfigurationSupportedAzureLocationsForSpokes: () => "/api/configuration/validation/azureLocations/spokes-values",
  ConfigurationConfiguredSpokeTopologies: () => "/api/configuration/validation/spokes/topologies-values",
}

@Injectable({providedIn: 'root'})
export class ConfigurationService extends BaseApiService{

  private deploymentStagesCache$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  private azureRegionsForHubsCache$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  private azureRegionsForSpokesCache$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  private spokeTopologiesCache$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);

  constructor(store: NpsStore) {
    super(store.apiUrl);
  }

  //#region Configuration

  tenants(): Observable<TenantDto[]> {
    return super.get<TenantDto[]>(NpsConfigurationApiUrl.ConfigurationTenants());
  }

  roles(): Observable<string[]> {
    return super.get<string[]>(NpsConfigurationApiUrl.ConfigurationUserRoles());
  }

  validators(endpoint: string): Observable<ValidationRule[]> {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { endpoint });
  }

  permissions(): Observable<PermissionDictionary> {
    return super.get<PermissionDictionary>(NpsConfigurationApiUrl.ConfigurationPermissions());
  }

  deploymentStages(): Observable<string[]> {
    return this.retrieveAvailablePropertyValuesWithCache(this.deploymentStagesCache$, NpsConfigurationApiUrl.ConfigurationConfiguredDeploymentStages());
  }

  azureRegionsForHubs(): Observable<string[]> {
    return this.retrieveAvailablePropertyValuesWithCache(this.azureRegionsForHubsCache$, NpsConfigurationApiUrl.ConfigurationSupportedAzureLocationsForHubs());
  }

  azureRegionsForSpokes(): Observable<string[]> {
    return this.retrieveAvailablePropertyValuesWithCache(this.azureRegionsForSpokesCache$, NpsConfigurationApiUrl.ConfigurationSupportedAzureLocationsForSpokes());
  }

  spokeTopologies(): any[] {
    return [
      {
        value: "Hybrid", label: "Yes, my application in Azure will interact with some applications hosted in CBB.",
      }, {
        value: "CloudNative", label: "No, my application in Azure will only interact with other applications hosted in Azure."
      }
    ];

    //return this.retrieveAvailablePropertyValuesWithCache(this.spokeTopologiesCache$, NpsConfigurationApiUrl.ConfigurationConfiguredSpokeTopologies());
  }

  private retrieveAvailablePropertyValuesWithCache(cache$: BehaviorSubject<string[] | null>, url: string): Observable<string[]> {
    if (!cache$.value) {
      return super.get<string[]>(url)
      .pipe(
        map(data => data.filter(item => item !== 'Invalid' && item !== 'Local')),
        tap(data => {
          cache$.next(data);
        })
      );
    }
    else {
      return <Observable<string[]>> cache$.asObservable();
    }
  }

  //#endregion
}
