import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private message: MessageService) { }

  success(title: string = 'title', text: string = 'success', life: number = 3000) {
    this.message.add({ severity: 'success', summary: title, detail: text, life: life });
  }

  error(title: string = 'title', text: string = 'error', life: number = 10000) {
    this.message.add({ severity: 'error', summary: title, detail: text, life: life/*, sticky: true*/ });
  }

  warning(title: string = 'title', text: string = 'warning', life: number = 3000) {
    this.message.add({ severity: 'error', summary: title, detail: text, life: life });
  }

  info(title: string = 'title', text: string = 'info', life: number = 3000) {
    this.message.add({ severity: 'error', summary: title, detail: text, life: life });
  }
}
