import { v4 as uuidv4 } from 'uuid';

export enum ProjectPermissionLevel {
  Unknown = "Unknown",
  ProjectMember = "Project Member",
  ProjectManager = "Project Manager",
  ProjectOwner = "Project Owner"
}

export class UserDto {
  userId: string = uuidv4(); // This will be a GUID
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  constructor(partial?: Partial<UserDto>) {
    Object.assign(this, partial);
  }
}

export enum UserRoles {
  Administrator = "Administrators",
  Operations = "Operations",
}

export class UserMeDto {
  userId: string = '';
  entraId: string = '';
  email: string = '';
  firstName: string = '';
  lastName: string = '';
  roles: string[] = [];
  //isAdministrator: boolean = false;
  //isOperations: boolean = false;
  projects: { [key: string]: ProjectPermissionLevel } = {};
}



