import { Injectable, OnDestroy } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { createStore, select, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { TenantKey } from '../../shared/models/tenant';
import { npsEnvironment } from '../environments/nps-environment';
import { UserMeDto } from '../features/shared/api/user-details.model';
import { RequestPermissionsType } from '../features/shared/api/configuration.model';

const npsStoreKey = 'nps.store';
interface NpsProps {
  user?: AccountInfo,
  npsUser?: UserMeDto ;
  tenant?: TenantKey;
  permissions?: { [key: string]: RequestPermissionsType };
}

const npsStore = createStore(
  { name: 'user' },
  withProps<NpsProps>({ })
);

//save in local storage
export const persist = persistState(npsStore, {
  key: npsStoreKey,
  storage: localStorageStrategy,
});

npsStore.subscribe((state) => console.log(state));
//persist.initialized$.subscribe(console.log);

@Injectable({ providedIn: 'root' })
export class NpsStore {

  store$ = npsStore;

  user$ = npsStore.pipe(select((state) => state.user));
  tenant$ = npsStore.pipe(select((state) => state.tenant));
  npsUser$ = npsStore.pipe(select((state) => state.npsUser));

  constructor() {
  }

  get current(): NpsProps {
    return npsStore.getValue();
  }

  get apiUrl(): string {
    return this.current.tenant ? npsEnvironment.api[this.current.tenant]!.url : '';
  }
  
  //get configuration(): NpsConfigService {
  //  return npsStore.getValue().configuration;
  //}

  setUser(user: AccountInfo | undefined) {
    npsStore.update((state) => ({
      ...state,
      user
    }));
  }

  setNpsUser(npsUser: UserMeDto | undefined) {
    npsStore.update((state) => ({
      ...state,
      npsUser
    }));
  }

  setPermissions(permissions: { [key: string]: RequestPermissionsType } | undefined) {
    npsStore.update((state) => ({
      ...state,
      permissions
    }));
  }

  setTenant(tenant: TenantKey | undefined) {
    if (tenant && !npsEnvironment.api[tenant])
      throw new Error(`There is no configuration for tenant ${tenant}`);

    npsStore.update((state) => ({
      ...state,
      tenant
    }));
  }

  cleanUser() {
    console.log("NpsStore.clean")
    //leave token
    this.setUser(undefined);
    this.setNpsUser(undefined);
    //npsStore.destroy();
  }
}
