
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { UserDto, UserMeDto, UserRoles } from './user-details.model';
import { NpsStore } from '../../../services/nps.store';
import { RequestPermissionsType } from './configuration.model';

export const NpsUserDetailsApiUrl = {
  UserDetailsSearch: (term: string) => `/api/users/search/${encodeURIComponent(term)}`,
  UserDetailsMe: "/api/users/me"
}

@Injectable({providedIn: 'root'})
export class UserDetailsService extends BaseApiService{

  constructor(private store: NpsStore) {
    super(store.apiUrl);
  }

  //#region User Profiles
  userSearch(term: string): Observable<UserDto[]> {
    return super.get(NpsUserDetailsApiUrl.UserDetailsSearch(term));
  }

  userMeGet(): Observable<UserMeDto> {
    return super.get(NpsUserDetailsApiUrl.UserDetailsMe);
  }

  //#endregion

  hasPermission(permissions: string, roles: string[] = []): boolean {
    //if user has Admin or Ops role, return true
    if (roles.includes(UserRoles.Administrator) || roles.includes(UserRoles.Operations)) {
      return true;
    }

    const permissionsArray = (permissions?.split(',') ?? [])
      .map(p => this.store.current?.permissions?.[p] ?? RequestPermissionsType.Operations);

    if (!permissionsArray.length) {
      return true;
    }

    const allowed = [];
    //check endpoint permissions in array
    for (let permission of permissionsArray) {
      switch (permission) {
        case RequestPermissionsType.Forbidden:
          allowed.push(false);
          break;
        case RequestPermissionsType.AnyUser:
          allowed.push(true);
          break;
        case RequestPermissionsType.Operations:
          allowed.push(roles.includes(UserRoles.Operations) ?? roles.includes(UserRoles.Administrator) ?? false);
          break;
        case RequestPermissionsType.Administrator:
          allowed.push(roles.includes(UserRoles.Administrator) ?? false);
          break;
        default:
          allowed.push(false);
      }
    }
    //if any is true, return true
    return allowed.some(p => p);
  }
}
